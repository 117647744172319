
import { onMounted, reactive, ref, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { createVisitRecord } from "../../config/interface";
import { ElMessage } from "element-plus";
import type { ElForm } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const ruleFormRef = ref<FormInstance>();
		const token = JSON.parse(localStorage.getItem('ACCESS_TOKEN') as string)
    let data = reactive({
      back: "<",
      visitInfo: {
				coId: route.query.coId,
        visitRecord: "",
        nextDay: "",
				userId: token.id,
				authority: token.authority,
				type: route.query.type
      },
			
      rules: {
        date: [
          {
            required: true,
            message: "请选择回访日期",
            trriger: "change",
          },
        ],
        record: [
          {
            required: true,
            message: "请输入回访记录",
            trriger: "blur",
          },
        ],
        nextVisit: [
          {
            required: true,
            message: "请选择下次回访时间",
            trriger: "change",
          },
        ],
      },
    });
    const handleCreate = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          console.log(data.visitInfo);
					createVisitRecord(data.visitInfo).then((res)=>{
						console.log(res);
						if(res.data && res.data.success) {
							ElMessage.success(res.data.msg)
							router.back();
						} else {
							ElMessage.error(res.data.msg)
						}
					})
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };
    const handleBack = () => {
      router.back();
    };
    onMounted(() => {
      console.log(route.query.coId);
    });
    return {
      route,
      router,
      ruleFormRef,
      ...toRefs(data),
      handleCreate,
      handleBack,
    };
  },
};
